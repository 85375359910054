@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Anek Telugu", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  @apply p-4;
  @apply rounded-xl;
  @apply shadow-md;
}

.title-header {
  @apply font-bold;
  @apply text-xl;
  @apply border-b;
  @apply border-black;
  @apply pb-1;
}

.section-header {
  @apply text-4xl;
  @apply font-bold;
}
